import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {InlineSvgPlugin} from 'vue-inline-svg';
import "animate.css";
import VueWow from "vue-wow";
import "@/assets/style/style.scss";
import "@/assets/style/style-page-interna.scss";
import store from './store';

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(InlineSvgPlugin);
Vue.use(VueWow);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
