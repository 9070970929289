<template>
   <div class="box-footer">
      <section class="section-na-midia">
         <div class="box-midia">
            <b-container class="h-100">  
               <div class="na-midia">
                  <h2 class="title-section">SOBRE O QUE <span>ESTAMOS FALANDO</span></h2>
                  <p>Acompanhe nossas ultimas postagens, o que estamos falando e o que anda acontecendo aqui na Insight :)</p>

                  <ul class="midias-sociais">
                     <li class="btn-padrao">
                        <a href="https://www.facebook.com/insightsolutionbr" target="_blank"><img class="svg" src="@/assets/images/facebook.svg" alt="Facebook"></a>
                     </li>
                     <li class="btn-padrao">
                        <a href="https://www.instagram.com/insightsolutionbr/" target="_blank"><img class="svg" src="@/assets/images/instagram.svg" alt="Instagram"></a>
                     </li>
                     <!-- <li class="btn-padrao">
                        <a href="https://www.linkedin.com/company/insightsolutionbr" target="_blank"><img class="svg" src="@/assets/images/twitter.svg" alt="linkedin"></a>
                     </li> -->
                  </ul>
               </div>

               <div class="no-insta wow fadeInUp" data-wow-delay="100ms">
                  <div class="img-instagram wow fadeIn" data-wow-delay="500ms"><a href="https://www.instagram.com/p/CDMnpxjBgSU/" target="_blank"><img src="@/assets/images/img-instagram.png" alt=""></a></div>
               </div>               
            </b-container>
         </div>
      </section>

      <div class="rodape">
         <div class="px-3">
            <div class="logo-rodape">
               <img src="@/assets/images/logo-rodape.svg" alt="Insight Solution">
            </div>
         
            <div class="info-rodape">
               <div class="menu-rodape">
                  <ul>
                     <li><a href="./">Início</a></li>
                     <li><a href="quem-somos">Quem somos</a></li>
                     <li><a href="contato">Contato</a></li>
                  </ul>
                  <ul class="social-media">
                     <!-- <li><a href="https://api.whatsapp.com/send?phone=5541996748555" target="_blank" rel="noopener"><img class="svg" src="@/assets/images/icone-whatsapp.svg" alt="Whatsapp"></a></li> -->
                     <li><a href="https://www.facebook.com/insightsolutionbr" target="_blank"><img class="svg" src="@/assets/images/icone-facebook.svg" alt="Facebook"></a></li>
                     <li><a href="https://www.instagram.com/insightsolutionbr/" target="_blank"><img class="svg" src="@/assets/images/icone-instagram.svg" alt="Instagram"></a></li>
                  </ul>
               </div>
               
               <div class="enderece">
                  <p class="text-big"><strong>Contato</strong><br>
                  comercial@insightsolution.com.br<br>
                  <!-- (41) 99674-8555<br>
                  Whatsapp (41) 98701-4761<br> -->
                  Curitiba - Paraná</p>
               </div>
            </div>
         </div>

         <div class="barra-onda"></div>
      </div>

      <div class="bar-footer">
         <b-container class="h-100">
            <p>@ Todos os direito reservados.</p>
         </b-container>
      </div>
   </div>  
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

<style lang="scss">
  @import "appFooter.scss";
</style>
