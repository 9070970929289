<template>
  <div id="app">
    <header>
      <appHeader></appHeader>
    </header>

    

    <div class="box-page">
      <router-view/>
    </div>

    <footer>
      <appFooter></appFooter>
    </footer>
  </div>
</template>

<script>
  import appHeader from '@/components/Header/AppHeader'
  import appFooter from '@/components/Footer/appFooter'

  export default {
    name: 'App',

    components: {
      appHeader,
      appFooter
    },
  }
</script>