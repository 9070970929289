<template>
  <div class="menu-topo">
    <button @click="toggle"><inline-svg :src="require(`@/assets/images/icone-menu-mobile.svg`)" /></button>

    <Drawer @close="toggle" align="left" :closeable="true">
      <div v-if="open">
        <div class="box-top-menu">
          <h1 class="logoTopo" @click="closeDrop"><router-link to="./"><img src="@/assets/images/logo.svg" alt=""></router-link></h1>
        </div>

        <ul>
          <li @click="closeDrop">
            <router-link to="./">Início</router-link>
          </li>
          <li @click="closeDrop">
            <router-link to="./quem-somos">Quem somos</router-link>
          </li>
          <li @click="closeDrop">
            <router-link to="./contato">Contato</router-link>
          </li>
          <li class="page-ex" @click="closeDrop">
            <a href="./nft">Serviços de Blockchain</a>
          </li>
          <li class="page-ex" @click="closeDrop">
            <a href="http://tagencia.com.br/" target="_blank">Agência TAG</a>
          </li>
        </ul>
      </div>
    </Drawer>
 </div>
</template>

<script>
import Drawer from "vue-simple-drawer"

export default {
  name: "MenuTopo",
  data() {
    return {
      open: false,
      align: "right",
      innerOpen: false,
      mask: false,
      maskClosable: false,
      close: true,
    }
  },
  components: {
    Drawer
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
    closeDrop() {
      this.open = false;
    },
  }
}
</script>

<style lang="scss">
  @import "MenuMobile.scss";
</style>