<template>
  <div class="h-100">
    <div class="d-flex h-100">
      <h1 class="logoTopo"><router-link to="./"><img src="@/assets/images/logo.svg" alt="Insight Solution"></router-link></h1>

      <div class="menu">
        <ul>
          <li>
            <router-link to="./">Início</router-link>
          </li>
          <li>
            <router-link to="./quem-somos">Quem somos</router-link>
          </li>
          <li>
            <router-link to="./contato">Contato</router-link>
          </li>
          <li class="page-ex">
            <a href="./nft">Serviços de Blockchain</a>
          </li>
          <li class="page-ex">
            <a href="http://tagencia.com.br/" target="_blank">Agência TAG</a>
          </li>
        </ul>
      </div>

      <div class="box-menuMobile">
        <MenuMobile></MenuMobile>
      </div>

      <div class="insight-blockChain">
        <a href="./nft"><img src="@/assets/images/insight-blockChain.svg" alt="Insight BlockChain"></a>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuMobile from '@/components/MenuMobile/MenuMobile'

  export default {
    name: 'AppHeader',

    components: {
      MenuMobile
    },
  }
</script>

<style lang="scss">
  @import "AppHeader.scss";
</style>
